import { Divider } from '@mantine/core';
import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import { fonts } from '@tyb-u/tyb-ui-components/theme';
import Link from 'next/link';
import { useCallback } from 'react';
import { isCollectibleEqual } from 'src/utils/compareCollectibles';
import { parseJson } from 'src/utils/parseJson';

import { AuthStatus } from '../../redux/auth/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { ICollectible, ICollection } from '../../types';
import CollectibleMedia from '../CollectibleMedia';

interface BrandCollectiblePreviewProps {
  brandName: string;
  collectible: ICollectible;
  brandCollections: Array<ICollection>;
  buttonText: string;
  buttonDisabled?: boolean;
  actions?: {
    login: string;
    unlock: () => void;
  };
  width?: string;
}

const BrandCollectiblePreview: React.FC<BrandCollectiblePreviewProps> = ({
  brandName,
  collectible,
  brandCollections,
  buttonText,
  buttonDisabled = false,
  actions,
  width = '450px',
}) => {
  const authStatus = useAppSelector(({ auth }) => auth.status);

  const getAdditionalDetails = useCallback(() => {
    //TODO: improve extraMedatada handling. It's probably better to work on it once everything is migrated over to the world-v2 project
    const collection = brandCollections.find((v) =>
      isCollectibleEqual(collectible, collectible.tokenId ? v : { contractAddress: v.contractAddress })
    );

    const additionalDetailsRaw = collection.collectibleExtraMetadatas.length
      ? collection.collectibleExtraMetadatas[0].additionalDetails
      : null;

    return (
      additionalDetailsRaw &&
      additionalDetailsRaw !== '[{}]' &&
      parseJson(additionalDetailsRaw) &&
      parseJson(additionalDetailsRaw)[0]
    );
  }, [brandCollections, collectible]);

  const additionalDetails = getAdditionalDetails();

  return (
    <Box maxWidth={width}>
      <Flex flexDirection="column" data-testid="brand-collectible-preview-painel">
        <Box __css={{ border: '1px solid #E1E0E0', borderRadius: '10px', backgroundColor: 'white' }}>
          <Flex flexDirection="column" alignItems="center" p="24px" data-testid="brand-collectible-preview">
            <Flex justifyContent="center" py="24px">
              <CollectibleMedia borderRadius="8px" metadata={collectible.metadata} width="200px" height="200px" />
            </Flex>
            <Box>
              <Text textAlign="center">
                You need the <span style={{ fontWeight: 'bold' }}>{collectible.metadata.name}</span> collectible to join
                the&nbsp;
                {brandName} community.
              </Text>
            </Box>
            <Flex py="24px" width="100%" justifyContent="center">
              <Button maxWidth="160px" width="100%" height="45px" onClick={actions?.unlock} disabled={buttonDisabled}>
                {buttonText}
              </Button>
            </Flex>

            {authStatus !== AuthStatus.LOGGED_IN && (
              <>
                <Box width="100%">
                  <Divider />
                </Box>
                <Box pt="24px">
                  <Text textAlign="center">
                    Already a member of this community?&nbsp;
                    <Link href={actions?.login || '#'}>
                      <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>Login.</span>
                    </Link>
                  </Text>
                </Box>
              </>
            )}
          </Flex>
        </Box>
        {additionalDetails && (
          <Flex
            __css={{ backgroundColor: 'white' }}
            flexDirection="column"
            alignItems="center"
            data-testid="brand-collectible-preview-add-details"
          >
            <Text py="20px" textAlign="center" variant="text5-600">
              {additionalDetails.title}
            </Text>
            <Box p="20px" __css={{ border: '1px solid #E1E0E0', borderRadius: '10px', width: '100%' }}>
              <Box
                __css={{
                  p: { ...(fonts['text5-400'] as any), marginBottom: '10px' },
                  a: {
                    'text-decoration': 'underline;',
                  },
                  'a:hover': {
                    'text-decoration': 'none;',
                  },
                  ul: {
                    'list-style-position': 'inside',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: additionalDetails.body }}
              />
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default BrandCollectiblePreview;

import { Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import copy from 'copy-to-clipboard';

import { getBrandPageUrl } from '../../utils/getBrandPageUrl';
import { successToast } from '../../utils/toasts';

type BrandPublicPageLinkProps = {
  urlSlug: string;
};

const BrandPublicPageLink: React.FC<BrandPublicPageLinkProps> = ({ urlSlug }) => {
  const url = getBrandPageUrl(urlSlug);

  const handleCopyLink = () => {
    copy(url.link);

    successToast('Copied to clipboard!');
  };

  return (
    <Flex mb="3">
      <Text variant="text5-400" mr="2">
        <span style={{ padding: 2 }}>{url.text()}</span>
      </Text>

      <Flex alignItems="center" __css={{ cursor: 'pointer' }} onClick={handleCopyLink}>
        <Icon.BoxiconsRegular.Copy size={12} />
      </Flex>
    </Flex>
  );
};

export default BrandPublicPageLink;

import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';

import Loading from '../components/Loading';
import PageLayout from '../components/PageLayout';
import useSelectedBrand from '../hooks/useSelectedBrand';
import { AuthStatus } from '../redux/auth/authSlice';
import { useAppSelector } from '../redux/hooks';

const withSelectedBrand: (WrappedComponent: React.FC) => React.FC = (WrappedComponent) => {
  const RequiresBrand = (props) => {
    const router = useRouter();
    const state = useAppSelector((state) => state.selectedBrand);
    const brand = useSelectedBrand();

    const { urlSlug } = router.query as Record<string, string>;
    const authStatus = useAppSelector(({ auth }) => auth.status);
    const user = useAppSelector((state) => state.user);

    const needRefresh = useMemo(() => {
      if (urlSlug) {
        if (urlSlug != state.slug || state.dateTime < user.sessionDateTime) {
          return true;
        }
      }

      return false;
    }, [urlSlug, state.slug, state.dateTime < user.sessionDateTime]);

    useEffect(() => {
      (async () => {
        if (needRefresh) {
          await brand.fetch(urlSlug);
        }
        // else if (state.slug) {
        //   await brand.reset();
        // }
      })();
    }, [urlSlug, needRefresh]);

    return state.loading || needRefresh ? (
      <PageLayout isAuthenticated={authStatus === AuthStatus.LOGGED_IN}>
        <Loading />
      </PageLayout>
    ) : (
      <WrappedComponent {...props} />
    );
  };

  return RequiresBrand;
};

export default withSelectedBrand;

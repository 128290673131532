import { useMutation } from '@apollo/client';
import { Flex } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { JOIN_BRAND, JoinBrandVars } from 'src/graphql/mutations/joinBrand';
import useAuth from 'src/hooks/useAuth';
import { AuthStatus } from 'src/redux/auth/authSlice';

import BrandCollectiblePreview from '../../components/BrandCollectiblePreview';
import { BRAND_ONBOARDING_CARD_WIDTH } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import { ICollectible } from '../../types';

const BrandOnboardingCard: React.FC = () => {
  const router = useRouter();

  const selectedBrand = useAppSelector((state) => state.selectedBrand.data);
  const authStatus = useAppSelector(({ auth }) => auth.status);
  const user = useAppSelector(({ user }) => user);

  const membershipCollectible = selectedBrand?.membershipCollectible as ICollectible;
  const auth = useAuth();

  const [joinBrand, { loading: joinBrandLoading }] = useMutation<unknown, JoinBrandVars>(JOIN_BRAND, {
    fetchPolicy: 'no-cache',
  });

  const {
    query: { mode },
  } = router;

  const startOnboarding = useCallback(async () => {
    if (selectedBrand.hasMainOnboarding) {
      const domain = process.env.NEXT_PUBLIC_DOMAIN.replace('app.', '');

      // TODO: check why this is not working -> middleware is changing the route
      // router.push(`https://${selectedBrand.urlSlug}.${domain}/join`);
      window.open(`https://${selectedBrand.urlSlug}.${domain}/join`, '_self');
      return;
    }

    if (authStatus === AuthStatus.LOGGED_OUT) {
      auth.enableDynamicLogin({ brand: selectedBrand.urlSlug, mode: 'unlock' });
      return;
    } else if (authStatus === AuthStatus.LOGGED_IN) {
      await joinBrand({
        variables: {
          brandSlug: selectedBrand.urlSlug,
        },
      });
    }
  }, [selectedBrand.hasMainOnboarding]);

  useEffect(() => {
    if (mode == 'unlock' && authStatus === AuthStatus.LOGGED_IN && !!user?.activeBrands.length) {
      startOnboarding();
    }
  }, [mode, authStatus, user?.activeBrands, startOnboarding]);

  return (
    <Flex
      width={['100%', '100%', BRAND_ONBOARDING_CARD_WIDTH]}
      alignItems={['center']}
      justifyContent={['center']}
      my="2"
      mx="2"
      mt={['none', 'none', '150px']}
    >
      {membershipCollectible && (
        <BrandCollectiblePreview
          brandName={selectedBrand.name}
          collectible={membershipCollectible}
          brandCollections={selectedBrand.brandWallet.collections}
          buttonText={'Unlock'}
          buttonDisabled={joinBrandLoading}
          actions={{
            login: '/login',
            unlock: () => {
              startOnboarding();
            },
          }}
        />
      )}
    </Flex>
  );
};

export default BrandOnboardingCard;

export const getBrandPageUrl = (urlSlug: string) => {
  const link = `${urlSlug}.${
    process.env.NEXT_PUBLIC_DOMAIN.startsWith('app.')
      ? process.env.NEXT_PUBLIC_DOMAIN.replace('app.', '')
      : process.env.NEXT_PUBLIC_DOMAIN
  }`;

  return {
    link,

    text() {
      return link.replace(/(^\w+:|^)\/\//, '');
    },
  };
};

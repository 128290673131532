import { Box } from '@tyb-u/tyb-ui-components';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import ImageCDN from '../ImageCDN';

const BrandHeroImage: React.FC<{ heroImageUrl: string; showPlaceholder?: boolean }> = ({
  heroImageUrl,
  showPlaceholder,
}) => {
  const isTabletDevice = useMediaQuery({ query: '(min-width: 831px) and (max-width: 1200px)' });
  const heroImageHeight = useMemo(() => (isTabletDevice ? '360px' : '480px'), [isTabletDevice]);

  if (!heroImageUrl) {
    return showPlaceholder ? <Box bg="gray02" width="100%" height={heroImageHeight} /> : null;
  }

  return (
    <Box display={['none', 'none', 'inherit']} position="relative">
      <ImageCDN
        src={heroImageUrl}
        width="100%"
        height={heroImageHeight}
        borderRadius="none"
        style={{ objectFit: 'cover' }}
        resizeTo="original"
      />
    </Box>
  );
};

export default BrandHeroImage;

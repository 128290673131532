import { Box, Button, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';
import { cdnPath } from 'src/utils';

import BioSocial from '../BioSocial';
import BrandPublicPageLink from '../BrandPublicPageLink';

interface BrandBioProps {
  logo?: string;
  uuid: string;
  name: string;
  description: string;
  showPublicPageLink?: boolean;
  descriptionDisplay?: string[] | string;
  urlSlug?: string;
  isFollowing?: boolean;
  instagramUrl?: string;
  twitterUrl?: string;
  tiktokUrl?: string;
  showSocialIcons?: boolean;
}

const BrandBio: React.FC<BrandBioProps> = ({
  logo,
  uuid,
  name,
  description,
  showPublicPageLink,
  descriptionDisplay = ['none', 'none', 'inherit'],
  urlSlug,
  isFollowing,
  instagramUrl,
  twitterUrl,
  tiktokUrl,
  showSocialIcons = true,
}) => {
  return (
    <>
      <Flex mb="2" alignItems="center" justifyContent="space-between" data-testid="brand-bio">
        {logo ? (
          <div
            style={{
              flex: 1,
              minHeight: '62px',
              alignSelf: 'center',
              flexGrow: 1,
              display: 'flex',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${cdnPath(logo)})`,
              backgroundPositionY: 'center',
            }}
          />
        ) : (
          <Text fontWeight="600" variant="text0" fontSize="42px" ml={logo ? '4' : '0'}>
            {name}
          </Text>
        )}
        {isFollowing && (
          <Button variant="secondary" style={{ cursor: 'default' }} mb="2" mt="2" p="2" px="3" ml="3">
            Joined
            <Box ml="2">
              <Icon.BoxiconsRegular.CheckCircle size={18} />
            </Box>
          </Button>
        )}
        {!isFollowing && showSocialIcons && (
          <BioSocial
            //website={brandPage.websiteUrl}
            brand={{
              name,
              uuid,
            }}
            instagram={instagramUrl}
            twitter={twitterUrl}
            tiktok={tiktokUrl}
            origin="BRAND"
          />
        )}
      </Flex>
      {showPublicPageLink && <BrandPublicPageLink urlSlug={urlSlug} />}
      <Box display={descriptionDisplay}>
        <Text mb="2" variant="text4-600">
          {`About ${name}`}
        </Text>
        <Text mb="2">{description}</Text>
      </Box>
    </>
  );
};

export default BrandBio;

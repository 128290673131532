import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { BrandEventTabs } from 'src/constants';
import { useTheme } from 'styled-components';

import AddressCollectibles from '../../containers/AddressCollectibles';
import { BrandEventData } from '../../graphql/queries/brandEvents';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { IBrandWallet } from '../../interface/IBrandWallet';
import { IBrandReward } from '../../types';
import { convertRemToPx } from '../../utils';
import BrandEventCard from '../Events/BrandEventCard';
import HorizontalScroll from '../HorizontalScroll';
import Loading from '../Loading';
import BrandEventsTabs from './BrandEventsTabs/BrandEventsTabs';

export interface BrandEventsProps {
  events: BrandEventData[];
  brandWallet: IBrandWallet;
}

export const BrandEvents: React.FC<BrandEventsProps> = ({ events, brandWallet }) => {
  const theme = useTheme();
  const size = useWindowSize();
  const [selectedTab, setSelectedTab] = useState<BrandEventTabs>(BrandEventTabs.Upcoming);
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const filteredEvents = useMemo(() => {
    if (selectedTab === BrandEventTabs.Upcoming) {
      return events?.filter((event) => dayjs(event.endDate).isAfter(new Date())) || [];
    } else {
      return events?.filter((event) => dayjs(event.endDate).isBefore(new Date())) || [];
    }
  }, [events, selectedTab]);

  return (
    <Box data-testid="brand-events">
      {events?.length > 0 && (
        <>
          <BrandEventsTabs selectedTab={selectedTab} onChangeTab={setSelectedTab} />
          <AddressCollectibles address={brandWallet.address} collections={brandWallet.collections} asContractOwner>
            {(_, { isReady, findCollectible }) =>
              !isReady ? (
                <Loading />
              ) : filteredEvents?.length > 0 ? (
                <HorizontalScroll height={700} disableOnMobile>
                  {filteredEvents?.map((event) => {
                    const reward: IBrandReward = event.reward
                      ? {
                          type: event.reward.type,
                          quantity: event.reward.quantity,
                          collectible: findCollectible(event.reward.assetId, event.reward.contractAddress),
                        }
                      : null;
                    const gateCollectibles = event.gates.map((v) => findCollectible(v.tokenId, v.contractAddress));

                    return (
                      <Flex key={event.uuid} data-testid="brand-event" mr="2">
                        <BrandEventCard
                          event={event}
                          reward={reward}
                          gateCollectibles={gateCollectibles}
                          brandWallet={brandWallet}
                          width={isMobile ? '100%' : undefined}
                        />
                      </Flex>
                    );
                  })}
                </HorizontalScroll>
              ) : (
                <Text color="neutrals-700">Nothing here yet</Text>
              )
            }
          </AddressCollectibles>
        </>
      )}
      {events?.length === 0 && (
        <Text color="neutrals-700" mt="15px">
          Nothing here yet
        </Text>
      )}
    </Box>
  );
};

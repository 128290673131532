import { NetworkStatus, useQuery } from '@apollo/client';
import { Box, Text } from '@tyb-u/tyb-ui-components';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import AddressCollectibles from 'src/containers/AddressCollectibles';
import { useTheme } from 'styled-components';

import {
  BRAND_AVAILABLE_REPCARDS,
  BrandPageRepCard,
  BrandRepCardEnum,
  IBrandAvailableRepCardsData,
  IBrandAvailableRepCardsVars,
} from '../../graphql/queries/brandPage';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { ICollection } from '../../types';
import { convertRemToPx, getRepCardRewardMetadata } from '../../utils';
import HorizontalScroll from '../HorizontalScroll';
import Loading from '../Loading';
import RepCard from '../RepCard';
import Tab from '../Tab';

export type UserBrandChallengesRef = {
  refetch: () => void;
};

interface UserBrandChallengesProps {
  repeatParticipations: boolean;
  brand: {
    uuid: string;
    name: string;
  };
  onlyPreview?: boolean;
  brandWallet: {
    address: string;
    collections: ICollection[];
  };
  openRepCardId?: number;
  onCompleteRepCardOpen?: (repCardId: string, repCard?: BrandPageRepCard) => Promise<void> | void;
  onCompleteRepCardClose?: () => Promise<void> | void;
}

enum CHALLENGES_TABS {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
}

const MAP_TAP_TO_REPCARD_TYPE = {
  OPEN: BrandRepCardEnum.AVAILABLE,
  CLOSED: BrandRepCardEnum.COMPLETED,
  ARCHIVED: BrandRepCardEnum.ARCHIVED,
};

const ActiveChallengesTab: React.FC<{
  selectedTab: CHALLENGES_TABS;
  onChangeTab: (tab: CHALLENGES_TABS) => void;
  disabled?: boolean;
}> = ({ selectedTab, onChangeTab, disabled }) => (
  <Tab selectedTab={selectedTab} onClick={onChangeTab}>
    <Tab.Item disabled={disabled} tab={CHALLENGES_TABS.OPEN} fontSize="16px">
      Active
    </Tab.Item>
    <Tab.Item disabled={disabled} tab={CHALLENGES_TABS.CLOSED} fontSize="16px">
      Completed
    </Tab.Item>
    <Tab.Item disabled={disabled} tab={CHALLENGES_TABS.ARCHIVED} fontSize="16px">
      Inactive
    </Tab.Item>
  </Tab>
);

const UserBrandChallenges: React.ForwardRefRenderFunction<UserBrandChallengesRef, UserBrandChallengesProps> = (
  {
    brand,
    repeatParticipations,
    brandWallet,
    onlyPreview,
    openRepCardId,
    onCompleteRepCardOpen = () => null,
    onCompleteRepCardClose = () => null,
  },
  ref
) => {
  const theme = useTheme();
  const size = useWindowSize();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[0]);
  const [reachToEnd, setReachToEnd] = useState(false);
  const [doFetch, setDoFetch] = useState(false);

  const [selectedChallengesTab, setSelectedChallengesTab] = useState<CHALLENGES_TABS>(CHALLENGES_TABS.OPEN);
  const [repcards, setRepcards] = useState<BrandPageRepCard[]>([]);

  const onDiscardRepcard = () => {
    load();
  };

  const {
    loading,
    refetch: refetchRepCards,
    networkStatus,
  } = useQuery<IBrandAvailableRepCardsData, IBrandAvailableRepCardsVars>(BRAND_AVAILABLE_REPCARDS, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const loadingRepCards = loading || networkStatus != NetworkStatus.ready;

  useImperativeHandle(ref, () => ({
    refetch() {
      load(true);
    },
  }));

  useEffect(() => {
    setRepcards([]);
    load(true);
  }, [selectedChallengesTab]);

  useEffect(() => {
    if (doFetch) {
      load();
      setDoFetch(false);
    }
  }, [doFetch]);

  const load = useCallback(
    async (reset = false) => {
      const take = 5;
      const result = await refetchRepCards({
        data: {
          brandUuid: brand.uuid,
          type: repeatParticipations ? BrandRepCardEnum.REPEATABLE : MAP_TAP_TO_REPCARD_TYPE[selectedChallengesTab],
          take,
          skip: reset ? 0 : repcards.length,
        },
      });

      if (result?.data?.getBrandAvailableRepCards) {
        setRepcards(
          reset
            ? result?.data?.getBrandAvailableRepCards || []
            : [...repcards, ...result?.data?.getBrandAvailableRepCards]
        );

        if (result?.data?.getBrandAvailableRepCards?.length != take) {
          setReachToEnd(true);
        }
      }
    },
    [brand.uuid, refetchRepCards, repeatParticipations, selectedChallengesTab, repcards, setRepcards, setReachToEnd]
  );

  return (
    <Box>
      {!repeatParticipations && (
        <Box>
          <ActiveChallengesTab
            disabled={loadingRepCards}
            selectedTab={selectedChallengesTab}
            onChangeTab={(tab) => {
              setSelectedChallengesTab(tab);
            }}
          />
        </Box>
      )}

      <AddressCollectibles address={brandWallet.address} collections={brandWallet.collections} asContractOwner>
        {(collectibles) => {
          return loadingRepCards && !repcards.length ? (
            <Loading />
          ) : (
            <Box>
              {repcards.length === 0 ? (
                <Text color="neutrals-700">Nothing here yet</Text>
              ) : (
                <HorizontalScroll
                  height={420}
                  infinityScroll={{
                    reachToEnd: reachToEnd,
                    reachToEndIsLoading: loadingRepCards,
                  }}
                  onReachToEnd={() => {
                    setDoFetch(true);
                  }}
                >
                  {repcards.map((repcard) => (
                    <Box data-testid="challenge-painel" key={repcard.id} mb={2} mr={2}>
                      <RepCard
                        onlyPreview={onlyPreview}
                        elevation="low"
                        data={{
                          id: repcard.id.toString(),
                          uuid: repcard.uuid,
                          brandUuid: brand.uuid,
                          brandName: brand.name,
                          name: repcard.prompt,
                          type: repcard.type,
                          hideCollectibleResponses: repcard.hideCollectibleResponses,
                          coverImageUrl: repcard.coverImageUrl || '',
                          rewardType: repcard.reward.type,
                          rewardMetadata: getRepCardRewardMetadata(repcard.reward, collectibles, {
                            collectibleExtraMetadatas: brandWallet.collections.flatMap(
                              (cs) => cs.collectibleExtraMetadatas
                            ),
                          }),
                          rewardTokenId: repcard.reward.assetId,
                          rewardQuantity: repcard.reward.quantity,
                          timeLimit: repcard.timeLimit,
                          interactivity: repcard.interactivity,
                          hasCompleted: repcard.myConsolidatedParticipation?.status == 'COMPLETED',
                          participants: repcard.participantUsersHighlight,
                          participationCounts: repcard.participationCount,
                          repeatParticipations: repcard.repeatParticipations,
                          maxParticipations: repcard.maxParticipations,
                        }}
                        openCompleteModal={openRepCardId && repcard.id === openRepCardId}
                        showHide={selectedChallengesTab == 'OPEN'}
                        onDiscardRepcard={onDiscardRepcard}
                        onOpen={(repCardId) => onCompleteRepCardOpen(repCardId, repcard)}
                        onClose={(_status, hasNewCompletedParticipation) => {
                          if (hasNewCompletedParticipation) {
                            load(true);
                          }

                          onCompleteRepCardClose();
                        }}
                        width={isMobile ? '100%' : undefined}
                      />
                    </Box>
                  ))}
                </HorizontalScroll>
              )}
            </Box>
          );
        }}
      </AddressCollectibles>
    </Box>
  );
};

export default forwardRef(UserBrandChallenges);

import { gql } from '@apollo/client';

export interface JoinBrandVars {
  brandSlug: string;
}

export const JOIN_BRAND = gql`
  mutation JoinBrand($brandSlug: String!) {
    joinBrand(brandSlug: $brandSlug)
  }
`;

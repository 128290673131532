import { useQuery } from '@apollo/client';
import mParticle from '@mparticle/web-sdk';
import { useRouter } from 'next/router';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { toast } from 'react-toastify';
import {
  BRAND_AVAILABLE_REPCARDS,
  BrandPageRepCard,
  BrandRepCardEnum,
  IBrandAvailableRepCardsData,
  IBrandAvailableRepCardsVars,
} from 'src/graphql/queries/brandPage';
import { IPublicBrand } from 'src/interface/IPublicBrand';
import { logMparticleEvent } from 'src/utils/mparticle';
import { errorToast } from 'src/utils/toasts';

import useAuth from '../../hooks/useAuth';
import RepCardCompleteModal from '../../modals/RepCardCompleteModal/RepCardCompleteModal';
import { AuthStatus } from '../../redux/auth/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { getRepCardRewardMetadata } from '../../utils';
import AddressCollectibles from '../AddressCollectibles';

export type RepCardByURLRef = {
  updateUrl: (repCardId: string) => URL;
  openRepCardModal: (repCardId: string) => void;
  closeRepCardModal: () => void;
};

type RepCardByURLProps = {
  brand: IPublicBrand;
  onCompleted?: () => void;
};

const RepCardByURL: React.ForwardRefRenderFunction<RepCardByURLRef, RepCardByURLProps> = (
  { brand, onCompleted = () => null },
  ref
) => {
  const router = useRouter();
  const authStatus = useAppSelector(({ auth }) => auth.status);
  const [repCard, setRepCard] = useState<BrandPageRepCard>(null);
  const auth = useAuth();

  const { refetch } = useQuery<IBrandAvailableRepCardsData, IBrandAvailableRepCardsVars>(BRAND_AVAILABLE_REPCARDS, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const updateUrl = useCallback((repCardId?: string) => {
    const url = new URL(window.location.href);

    if (repCardId) {
      url.searchParams.set('repCardId', repCardId);
    } else {
      url.searchParams.delete('repCardId');
    }

    if (url.searchParams.toString().length > 0) {
      router.replace(`${location.pathname}?${url.searchParams.toString()}`, undefined, {
        scroll: false,
      });
    } else {
      router.replace(location.pathname, undefined, {
        scroll: false,
      });
    }

    return url;
  }, []);

  const fetchRepcard = useCallback(
    async (repCardId: string) => {
      const result = await refetch({
        data: {
          brandUuid: brand.uuid,
          repCardId: parseInt(repCardId),
          type: BrandRepCardEnum.VIEWABLE,
          take: 1,
          skip: 0,
        },
      });

      if (result?.data?.getBrandAvailableRepCards[0]) {
        setRepCard(result?.data?.getBrandAvailableRepCards[0]);
      } else {
        toast.info('Invalid challenge. If the error persists email us at help@tyb.xyz', {
          autoClose: false,
        });
      }
    },
    [refetch]
  );

  const openRepCardModal = useCallback(
    (repCardId: string) => {
      if (authStatus === AuthStatus.LOGGED_OUT) {
        auth.enableDynamicLogin();

        return null;
      }

      if (authStatus === AuthStatus.LOGGED_IN && !brand?.isMember) {
        toast.info(
          'You must be a member of this brand to complete this challenge. Become a member of this brand by unlocking the collectible.',
          {
            autoClose: false,
          }
        );
        return null;
      }

      fetchRepcard(repCardId);

      updateUrl(repCardId);
    },
    [fetchRepcard]
  );

  useImperativeHandle(ref, () => ({
    openRepCardModal,
    closeRepCardModal: () => updateUrl(null),
    updateUrl,
  }));

  return (
    repCard && (
      <AddressCollectibles
        address={brand.brandWallet.address}
        collections={brand.brandWallet.collections}
        asContractOwner
      >
        {(collectibles) => (
          <React.Suspense fallback={null}>
            <>
              <RepCardCompleteModal
                id={repCard.id.toString()}
                brandUuid={brand.uuid}
                rewardType={repCard.reward.type}
                rewardMetadata={getRepCardRewardMetadata(repCard.reward, collectibles, {
                  collectibleExtraMetadatas: brand.brandWallet.collections.flatMap(
                    (cs) => cs.collectibleExtraMetadatas
                  ),
                })}
                rewardTokenId={repCard.reward.assetId}
                isSimulation={false}
                isOpen={!!repCard}
                onClose={(status, hasNewCompletedParticipation) => {
                  updateUrl(null);
                  setRepCard(null);

                  if (hasNewCompletedParticipation) {
                    onCompleted();
                  }

                  if (brand?.uuid && brand?.name) {
                    let eventName = '';

                    if (hasNewCompletedParticipation) {
                      eventName = 'complete_repcard';
                    } else if (status == 'DISMISS') {
                      eventName = 'dismiss_repcard';
                    } else if (status == 'AGE_RESTRICTION_DISMISS') {
                      eventName = 'dismiss_age_restriction_repcard';
                    }

                    if (eventName) {
                      logMparticleEvent(eventName, mParticle.EventType.Other, {
                        brand_uuid: brand.uuid,
                        brand_name: brand.name.toLowerCase(),
                        repcard_prompt: repCard.prompt,
                        repcard_type: repCard.type?.toLowerCase(),
                        repcard_id: repCard.id,
                      });
                    }
                  }
                }}
                hasCompleted={repCard.myConsolidatedParticipation?.status == 'COMPLETED'}
                repeatParticipations={repCard.repeatParticipations}
                onPressButtonOnFinish={null}
                showButtonOnFinish
              />
            </>
          </React.Suspense>
        )}
      </AddressCollectibles>
    )
  );
};

export default forwardRef(RepCardByURL);

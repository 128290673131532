import { useQuery } from '@apollo/client';
import { Box, Flex, Grid, Text } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import BrandHeroImage from 'src/components/BrandHeroImage';
import useBrandRequerimentVerification from 'src/hooks/useBrandRequerimentVerification';

import BioSocial from '../../../components/BioSocial';
import BrandBio from '../../../components/BrandBio';
import { BrandEvents } from '../../../components/BrandEvents/BrandEvents';
import CollectiblePreview from '../../../components/CollectiblePreview';
import Logo from '../../../components/Logo';
import PageLayout from '../../../components/PageLayout';
import TeamMembersPreview from '../../../components/TeamMembersPreview';
import UserBrandChallenges from '../../../components/UserBrandChallenges';
import { UserBrandChallengesRef } from '../../../components/UserBrandChallenges/UserBrandChallenges';
import { BRAND_ONBOARDING_CARD_WIDTH } from '../../../constants';
import AddressCollectibles from '../../../containers/AddressCollectibles';
import BrandOnboardingCard from '../../../containers/BrandOnboardingCard';
import RepCardByURL from '../../../containers/RepCardByURL';
import { RepCardByURLRef } from '../../../containers/RepCardByURL/RepCardByURL';
import { ACTIVE_BRAND_EVENTS, ActiveBrandEventsData } from '../../../graphql/queries/brandEvents';
import { BrandPageRepCard } from '../../../graphql/queries/brandPage';
import withSelectedBrand from '../../../hoc/withSelectedBrand';
// import useFullStory from '../../../hooks/useFullStory';
import CollectibleViewModal from '../../../modals/CollectibleViewModal/CollectibleViewModal';
import { AuthStatus } from '../../../redux/auth/authSlice';
import { useAppSelector } from '../../../redux/hooks';
import { ICollectible } from '../../../types';
import { getCollectibleMetadata } from '../../../utils/getCollectibleMetadata';

type AvailableTabs = 'Events' | 'Challenges';

const Brand = () => {
  const router = useRouter();
  // const FS = useFullStory();
  const userBrandChallengeRef = useRef<UserBrandChallengesRef>();
  const userOnGoingBrandChallengeRef = useRef<UserBrandChallengesRef>();
  const repCardByUrlRef = useRef<RepCardByURLRef>();

  const isMobileDevice = useMediaQuery({ query: '(max-width: 830px)' });

  const {
    query: { urlSlug, repCardId },
  } = router;

  const authStatus = useAppSelector(({ auth }) => auth.status);

  const [collectibleData, setCollectibleData] = useState<ICollectible>();
  const [isCollectibleModalOpen, setIsCollectibleModalOpen] = useState<boolean>(false);
  const [isCoin, setIsCoin] = useState<boolean>(false);
  const selectedBrand = useAppSelector((state) => state.selectedBrand.data);
  const [preventRepCardByUrl, setPreventRepCardByUrl] = useState(false);

  const { data: eventsData } = useQuery<ActiveBrandEventsData>(ACTIVE_BRAND_EVENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      brandUuid: selectedBrand.uuid,
      sort: 'desc',
    },
  });

  const { modal, attempt } = useBrandRequerimentVerification(selectedBrand);

  const handleOpenCompleteRepCard = useCallback(
    (repCardId: string, repCard: BrandPageRepCard) => {
      const url = repCardByUrlRef.current?.updateUrl(repCardId);

      if (repCard?.type === 'SOCIAL_SHARE' && url && !url.host.startsWith('app.')) {
        router.replace(`/brand/${urlSlug}?${url.searchParams.toString()}`, undefined, { scroll: false });
      }
    },
    [urlSlug]
  );

  useEffect(() => {
    if (repCardId && !preventRepCardByUrl) {
      repCardByUrlRef.current.openRepCardModal(repCardId as string);
    }
  }, [repCardId]);

  useEffect(() => {
    // FS.event('Brand Page Visit', {
    //   brand_id: selectedBrand.id,
    //   brand_name: selectedBrand.name,
    // });

    if (authStatus === AuthStatus.LOGGED_OUT) {
      attempt(false);
    } else {
      attempt(true);
    }

    setPreventRepCardByUrl(true);
  }, [attempt]);

  const isFollowing = selectedBrand.isMember;

  const TABS: { [key: string]: { title: AvailableTabs; isVisible: boolean } } = {
    challenges: { title: 'Challenges', isVisible: isFollowing },
    events: { title: 'Events', isVisible: isFollowing && eventsData?.activeBrandEvents?.length > 0 },
  };

  const isCollectibleVisible = (collectible?: Pick<ICollectible, 'contractAddress' | 'tokenId' | 'address'>) => {
    if (!collectible) return true;

    const collectibleSettings = selectedBrand.brandWallet.collectiblesSettings.find(
      (c) => c.contractAddress === collectible.contractAddress && c.tokenId === (collectible.tokenId || '')
    );

    return collectibleSettings?.visibility ?? true;
  };

  const isUnlockState = authStatus == AuthStatus.LOGGED_IN && selectedBrand.isMember;

  return (
    <PageLayout
      isAuthenticated={authStatus === AuthStatus.LOGGED_IN}
      noPadding
      hideNavbar={authStatus === AuthStatus.LOGGED_OUT}
      showSignup
      navbarMaxWidth={authStatus === AuthStatus.LOGGED_IN ? '100%' : '1220px'}
      mparticleEventName="page_view_brand"
      mparticleEventExtraData={{
        brand: urlSlug,
      }}
    >
      {modal}
      {!!selectedBrand && (
        <RepCardByURL
          ref={repCardByUrlRef}
          brand={selectedBrand}
          onCompleted={() => {
            userOnGoingBrandChallengeRef.current?.refetch();
            userBrandChallengeRef.current?.refetch();
          }}
        />
      )}
      <Flex flexDirection="column" width="100%" justifyContent="center">
        <BrandHeroImage heroImageUrl={selectedBrand.heroImageUrl} />
        <Flex flexDirection="column" width="100%" justifyContent="center" px="20px" mb="80px">
          <Flex flexDirection="column" width="100%">
            <>
              <Box px={[0, 0, 16]} mr={!isFollowing ? ['0', '0', BRAND_ONBOARDING_CARD_WIDTH + 250] : '0'}>
                <Box pt="4">
                  <BrandBio
                    uuid={selectedBrand.uuid}
                    logo={selectedBrand.logoUrl}
                    name={selectedBrand.name}
                    description={selectedBrand.description}
                    isFollowing={isFollowing}
                    instagramUrl={selectedBrand.instagramUrl}
                    twitterUrl={selectedBrand.twitterUrl}
                    tiktokUrl={selectedBrand.tiktokUrl}
                    showSocialIcons={isUnlockState}
                  />

                  <Box>
                    {isUnlockState && (
                      <Flex my="2" justifyContent="space-between">
                        <BioSocial
                          brand={{
                            name: selectedBrand.name,
                            uuid: selectedBrand.uuid,
                          }}
                          website={selectedBrand.websiteUrl}
                          minimalist={false}
                          origin="BRAND"
                        />
                        {isFollowing && (
                          <BioSocial
                            brand={{
                              name: selectedBrand.name,
                              uuid: selectedBrand.uuid,
                            }}
                            instagram={selectedBrand.instagramUrl}
                            twitter={selectedBrand.twitterUrl}
                            tiktok={selectedBrand.tiktokUrl}
                            origin="BRAND"
                          />
                        )}
                      </Flex>
                    )}

                    <Box mb={['4']} mt="4">
                      <TeamMembersPreview
                        brandUuid={selectedBrand.uuid}
                        brandName={selectedBrand.name}
                        teamMembers={selectedBrand.avatarRow.map((brandMember) => ({
                          id: brandMember.userId.toString(),
                          avatar: brandMember.user.avatarUrl,
                        }))}
                        max={10}
                        total={selectedBrand.brandMembersCount}
                        itemWidth={36}
                        showTitle={!isMobileDevice}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          </Flex>

          <Box position={['unset', 'unset', 'absolute']} top="24px" right="130px">
            {!isFollowing && (
              <Flex flexDirection="column" alignItems={['center']} justifyContent={['center']} width="100%" mb="4">
                <BrandOnboardingCard />
              </Flex>
            )}
          </Box>

          {isMobileDevice && (
            <Box>
              <Text mb="2" variant="text4-600">
                {`About ${selectedBrand.name}`}
              </Text>
              <Text mb="2">{selectedBrand.description}</Text>
            </Box>
          )}

          <Box
            mt={['4', '2', '0']}
            px={[0, 0, 16]}
            mr={!isFollowing ? ['0', '0', BRAND_ONBOARDING_CARD_WIDTH + 250] : 0}
          >
            <>
              <Flex width="100%" flexDirection="column" __css={{ gap: '50px' }}>
                {isFollowing && (
                  <Flex flexDirection="column" __css={{ gap: '15px' }}>
                    <Text variant="text3-600">Ongoing challenges</Text>
                    <UserBrandChallenges
                      ref={userOnGoingBrandChallengeRef}
                      repeatParticipations
                      brand={{
                        uuid: selectedBrand.uuid,
                        name: selectedBrand.name,
                      }}
                      brandWallet={selectedBrand.brandWallet}
                      onlyPreview={authStatus !== AuthStatus.LOGGED_IN}
                      onCompleteRepCardOpen={handleOpenCompleteRepCard}
                      onCompleteRepCardClose={repCardByUrlRef.current?.closeRepCardModal}
                    />
                  </Flex>
                )}

                {TABS['challenges'].isVisible && (
                  <Box>
                    <Text variant="text3-600">{TABS['challenges'].title}</Text>
                    <UserBrandChallenges
                      ref={userBrandChallengeRef}
                      repeatParticipations={false}
                      brand={{
                        uuid: selectedBrand.uuid,
                        name: selectedBrand.name,
                      }}
                      brandWallet={selectedBrand.brandWallet}
                      onlyPreview={authStatus !== AuthStatus.LOGGED_IN}
                      onCompleteRepCardOpen={handleOpenCompleteRepCard}
                      onCompleteRepCardClose={repCardByUrlRef.current?.closeRepCardModal}
                    />
                  </Box>
                )}

                {TABS['events'].isVisible && (
                  <Box>
                    <Text variant="text3-600">{TABS['events'].title}</Text>
                    <BrandEvents events={eventsData.activeBrandEvents} brandWallet={selectedBrand.brandWallet} />
                  </Box>
                )}

                <AddressCollectibles
                  address={selectedBrand.brandWallet.address}
                  collections={selectedBrand.brandWallet.collections}
                  asContractOwner
                >
                  {(collectibles) => {
                    return (
                      <>
                        {collectibles.length > 0 && (
                          <Flex
                            flexDirection="column"
                            maxWidth="840px"
                            alignSelf={['center', 'center', 'flex-start']}
                            mr={['0', '0', '4']}
                            width="100%"
                          >
                            <Box mb="2">
                              <Text mb="2" variant="text3-600">
                                Collectibles
                              </Text>
                            </Box>
                            <Grid
                              data-testid="brand-collectibles"
                              gridTemplateColumns={[
                                'repeat(auto-fill, minmax(95px, 1fr))',
                                'repeat(auto-fill, minmax(185px, 1fr))',
                              ]}
                              gridGap="3"
                            >
                              {collectibles
                                .filter((v) => isCollectibleVisible(v))
                                .map((collectible, idx) => (
                                  <Flex
                                    key={idx}
                                    onClick={() => {
                                      setCollectibleData({
                                        ...collectible,
                                        metadata: {
                                          ...collectible.metadata,
                                          ...getCollectibleMetadata({
                                            metadata: collectible.metadata,
                                            collectibleExtraMetadatas: selectedBrand?.brandWallet.collections.flatMap(
                                              (cs) => cs.collectibleExtraMetadatas
                                            ),
                                            contractAddress: collectible.contractAddress,
                                            type: collectible.type,
                                            tokenId: collectible.tokenId,
                                          }),
                                        },
                                      });
                                      setIsCoin(collectible.type === 'ERC20');
                                      setIsCollectibleModalOpen(true);
                                    }}
                                    __css={{ cursor: 'pointer' }}
                                  >
                                    <CollectiblePreview
                                      key={idx}
                                      collectible={collectible}
                                      width={['110px', '196px']}
                                      height={['110px', '196px']}
                                    />
                                  </Flex>
                                ))}
                            </Grid>
                            <CollectibleViewModal
                              isOpen={isCollectibleModalOpen}
                              onClose={setIsCollectibleModalOpen}
                              collectible={collectibleData}
                              isCoin={isCoin}
                              showTokenId={false}
                              isOnBrandPage={true}
                              isCollectibleVisible={isCollectibleVisible(collectibleData)}
                            />
                          </Flex>
                        )}
                      </>
                    );
                  }}
                </AddressCollectibles>
              </Flex>
            </>
          </Box>

          {/* {authStatus !== AuthStatus.LOGGED_IN && (
                    <Flex py="24px" justifyContent="center" display={['inherit', 'none', 'none']}>
                      <Box width="100%" py="7px" px="5px" border="1px solid #E1E0E0" borderRadius="50px">
                        <Text textAlign="center">
                          Already a member?&nbsp;
                          <Link href="/login">
                            <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>Login.</span>
                          </Link>
                        </Text>
                      </Box>
                    </Flex>
                  )} */}

          <Flex p="30px" justifyContent="center" display={['inherit', 'none', 'none']}>
            <Logo height={24} />
          </Flex>
        </Flex>
      </Flex>
    </PageLayout>
  );
};

export default withSelectedBrand(Brand);
